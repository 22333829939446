import { Component } from '@angular/core';
import { SiteService } from '@services/site.service';
import { SiteEnum } from '@enums/Site.enum';
import { environment } from '@environments';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  environment: any = environment;
  mail: string = '';

  constructor(protected siteService: SiteService) {}

  ngOnInit(): void {
    switch (this.siteService.site) {
      case SiteEnum.Ammom:
        this.mail = `educacion@${environment.domain}`;

        break;

      default:
        this.mail = `soporte@${environment.domain}`;

        break;
    }
  }
}
