<footer>
  <div class="row justify-content-between w-100 my-3">
    <div class="col-md-6 col-lg text-center">
      {{ environment.association.legal }}
    </div>

    <div class="col-md-6 col-lg text-center">
      <a class="amm-link" href="mailto:{{ mail }}">
        {{ mail }}
      </a>
    </div>

    <div class="col-md-6 col-lg text-center">
      <a
        class="amm-link"
        href="http://{{
          environment.domain +
            (siteService.site === 'ammom' ? '/congreso-anual' : '')
        }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ environment.domain }}
      </a>
    </div>

    <div class="col-md-6 col-lg text-center">
      <a
        class="amm-link"
        target="_blank"
        [href]="environment.micuenta + 'terms'"
      >
        Aviso de Privacidad
      </a>
    </div>
  </div>
</footer>
