import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { forkJoin } from 'rxjs';

import { ApiService } from '@services/api.service';

export class Params {
  take: number = 10;
  size: number = 10;
  search: string = '';
  page: number = 1;
  [key: string]: any;
}

@Component({
  selector: 'app-form',
  standalone: true,
  template: '',
})
export abstract class FormComponent {
  alertData: string | null = null;
  data: any = {};
  // * WHEN JUST ONE SERVICE IS REQUIRED
  loadEndpoint: string = '';
  // * WHEN MORE THAN ONE SERVICE IS REQUIRED
  services: string[] = [];
  // * USED FOR CRUD REQUESTS ONLY
  endpoint: string = '';
  method: string = 'get';

  initialized = false;
  loading: boolean = false;
  params: Params = new Params();

  constructor(protected api: ApiService, protected formBuilder: FormBuilder) {
    this.settings();
  }

  settings() {}

  loadServices() {
    if (this.services.length > 0) {
      this.startLoading();

      const services = this.services.map((serivce) => this.api.call(serivce));

      forkJoin(services).subscribe({
        next: (responses) => {
          this.onLoadServicesSuccess(responses);
        },
        error: (errors) => {
          this.onLoadServicesError(errors);
        },
      });
    }
  }

  onLoadServicesSuccess(responses: any[]): void {
    this.initialized = true;
    this.stopLoading();
  }

  onLoadServicesError(errors: any[]): void {
    this.initialized = true;
    this.stopLoading();
  }

  load() {
    this.startLoading();

    this.api
      .call(this.loadEndpoint, this.method, this.getQueryParams())
      .subscribe({
        next: (response) => {
          if (response.status == 200) {
            this.onLoadSuccess(response);
          } else {
            this.onLoadError(response.message);
          }
        },
        error: (error) => {
          console.error(error);
          this.onLoadError(error);
        },
      });
  }

  onLoadSuccess(response: any): void {
    this.stopLoading();
  }

  onLoadError(error: any): void {
    this.stopLoading();
  }

  getQueryParams() {
    const params: any = {};

    for (const [key, value] of Object.entries(this.params)) {
      if (value !== -1 && value !== null && value !== '') {
        params[key] = value;
      }
    }

    return params;
  }

  isValid(): boolean {
    return true;
  }

  getParams() {
    return this.data;
  }

  submit() {
    if (this.loading || !this.isValid()) return;

    this.startLoading();

    const params = this.getParams();

    this.api.call(this.endpoint, this.method, params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.success(response);
        } else {
          this.failed(response.message.join(', '));
        }
      },
      error: (error) => {
        console.error(error);

        this.failed(error.message ?? error);
      },
    });
  }

  success(response: any) {
    this.stopLoading();
  }

  failed(error: any) {
    this.handleAlert(error);

    this.stopLoading();
  }

  handleAlert(message: string, type: string = 'danger') {
    this.alertData = '';

    this.alertData = message;
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }
}
