import { SiteEnum } from '@enums/Site.enum';

export const environment = {
  analytics: '',
  cookie: 'amm_sys',
  domain: 'mastologia.org.mx',
  waPhone: '',
  key: 'amm',
  login: {
    title: 'Bienvenido a la Asociación Mexicana de Mastología',
    description:
      '37 años de experiencia en la educación multidisciplinaria del cáncer de mama',
  },
  association: {
    name: 'AMM',
    short_name: 'ASOCIACIÓN MEXICANA DE MASTOLOGÍA',
    legal: 'Asociación Mexicana de Mastología A. C. 2023',
    congress: 'XX Congreso Nacional de Mastología 2023',
  },
  endpoint: 'https://stage.cms.mastologia.org.mx/api/',
  stripeKey: 'pk_test_ddnwrRDUlXwRDQiuyi9raYQV00NkAXuNfw',
  production: false,
  site: SiteEnum.Amm,
  webcast: 'https://stage.webcast.mastologia.org.mx/',
  micuenta: 'https://micuenta.ammom.org/',
};
