import { Pipe, PipeTransform } from '@angular/core';
import { SiteEnum } from '@enums/Site.enum';

@Pipe({
  name: 'siteAssets',
  standalone: true,
})
export class SiteAssetsPipe implements PipeTransform {
  transform(assets: string, site: SiteEnum): string {
    return `${site}/${assets}`;
  }
}
