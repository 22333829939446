import { Component, HostListener, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ApiService } from '@services/api.service';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgClass } from '@angular/common';
import { FormComponent } from './components/form/form.component';
import QRCode, { QRCodeToDataURLOptions } from 'qrcode';
import { SiteAssetsPipe } from './pipes/site-assets.pipe';
import { SiteEnum } from '@enums/Site.enum';
import { SiteService } from '@services/site.service';

export class Profile {
  id: number = -1;
  name: string = '';
  last_name: string = '';
  email: string = '';
  state: string = '';
  code: string = '';
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    HeaderComponent,
    FooterComponent,
    SiteAssetsPipe,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent extends FormComponent {
  private siteService = inject(SiteService);

  step: number = 0;
  qrCode: string | null = '';
  profile: Profile = new Profile();
  profiles: Profile[] = [];
  code: string = '';
  site: SiteEnum = SiteEnum.Amm;
  badge: string = '';

  override data = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setAppHeight();
  }

  ngOnInit(): void {
    this.endpoint = 'portal/badges';
    this.method = 'get';
    this.site = this.siteService.site;
    this.loadEndpoint = 'portal/badges/congress';
    this.load();
  }

  override onLoadSuccess(response: any): void {
    this.badge = response.data?.image_badge || '';
    this.loading = false;
  }

  private setAppHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  override isValid(): boolean {
    this.alertData = '';
    this.data.enable();

    const valid = this.data.valid;

    if (valid) {
      this.data.disable();
    }

    return valid;
  }

  override getParams() {
    return this.data.value;
  }

  override failed(error: any): void {
    console.error(error);

    this.alertData =
      error ||
      'Cuenta no registrada o no cuenta con Congreso Presencial activo.';

    this.restart();
  }

  override success(response: any): void {
    this.profiles = response.data;

    if (this.profiles.length === 0) {
      this.failed(null);
      return;
    }

    if (this.profiles.length === 1) {
      this.confirm(0);
      return;
    }

    this.step = 1;
    this.loading = false;
  }

  confirm(index: number) {
    this.profile = this.profiles[index];

    this.generateQrCode();
  }

  generateQrCode(): void {
    const opts: QRCodeToDataURLOptions = {
      errorCorrectionLevel: 'H',
      width: 600,
      color: {
        dark: '#000000',
        light: '#ffffff',
      },
    };

    QRCode.toDataURL(this.profile.code, opts)
      .then((url) => {
        this.qrCode = url;
        this.step = 2;
        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
        this.code = '';

        this.alertData =
          'Ocurrio un error al generar el Gafete, por favor recarge la pestaña.';
        this.loading = false;
      });
  }

  printQrCode() {
    this.loading = true;

    // * CONGRESS REQUEST
    this.api.print(
      `portal/badges/${this.profile.id}`,
      (completed) => {
        if (!completed) {
          this.alertData =
            'Ocurrio un error al descargar su Gafete, por favor recarge la pestaña e intentelo de nuevo.';
        }

        this.api
          .call(`portal/badges/${this.profile.id}/print`, 'post', {})
          .subscribe({
            next: (response) => {
              this.loading = false;
              this.restart();
            },
            error: (error) => {
              this.loading = false;
              this.restart();
            },
          });
      },
      false
    );
  }

  override handleAlert(message: string, type?: string): void {}

  restart() {
    this.loading = true;
    this.data.reset();
    this.data.enable();
    this.code = '';
    this.qrCode = '';
    this.profile = new Profile();
    this.step = 0;
    this.load();
  }
}
