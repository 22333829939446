import { Injectable } from '@angular/core';
import { SiteEnum } from '../enums/Site.enum';
import { environment } from '@environments';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  site: SiteEnum = SiteEnum.Amm;

  constructor() {
    this.site = environment.site;
  }
}
