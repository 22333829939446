<header>
  <div class="row align-items-center justify-content-between w-100">
    <div class="col-12 col-lg mb-3 mb-lg-0 text-center text-md-start">
      <a class="header-logo text-decoration-none text-light" routerLink="/">
        <!-- src="assets/images/logo_header.png" -->
        <img
          class="clickable me-1 me-md-2"
          [src]="'images/logo_header.png' | siteAssets : site"
          alt="Logo Header"
        />

        {{ environment.association.short_name }}
      </a>
    </div>
  </div>
</header>
