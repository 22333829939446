import { Component, TemplateRef, ViewChild } from '@angular/core';
import { SiteService } from '@services/site.service';
import { SiteEnum } from '@enums/Site.enum';
import { environment } from '@environments';
import { SiteAssetsPipe } from '../../pipes/site-assets.pipe';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SiteAssetsPipe],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  site: SiteEnum = SiteEnum.Amm;
  environment: any = environment;

  @ViewChild('specialityModal', { read: TemplateRef })
  specialityModal?: TemplateRef<any>;

  constructor(private siteService: SiteService) {}

  ngOnInit(): void {
    this.site = this.siteService.site;
  }
}
